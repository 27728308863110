<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2"
                                @click="abrirFiltros" />

                        </div>
                    </template>

                    <template v-slot:end>
                        <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <!-- Tabela de modos de falha -->
                <DataTable ref="dt" :value="errosCockpit" v-model:expandedRows="selectImplantacoes" dataKey="id"
                    :paginator="true" :rows="20" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Cockpit - Extração</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <!-- <i class="pi pi-search" /> -->
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column field="id" header="Id" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">ID</span>
                            <span>{{ slotProps.data.id }}</span>
                        </template>
                    </Column>

                    <Column field="id" header="Id Fila" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">ID</span>
                            <span>{{ slotProps.data.fila_implantacao.id }}</span>
                        </template>
                    </Column>
                    
                    <Column field="razao_social" header="Empresa" :sortable="true"
                        headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Empresa</span>
                            <span>{{ slotProps.data.cliente.razao_social }}</span>
                        </template>
                    </Column>


                    <Column field="quantidade_arquivos" header="Modo de Falha" :sortable="true"
                        style="text-align: center;" headerStyle="width:12%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Modo de Falha</span>
                            <Tag severity="primary" :style="{ backgroundColor: '#ff4d4f',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                 }"
                               :value="slotProps.data.status_erro.descricao">
                            </Tag>
                        </template>
                    </Column>

                    

                    

                    <Column field="status" header="Status" :sortable="true" headerStyle="width:12%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Status</span>

                            <Tag severity="primary" 
                                :style="{ backgroundColor: slotProps.data.status === 0 ? '#999' : '#4caf50',
                                     width: '100%', 
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                 }"
                                :value="slotProps.data.status === 0 ? 'Aguardando correção' : 'Corrigido'"
                            >
                            </Tag>

                        </template>
                    </Column>

                    <Column field="quantidades_arquivos" header="Quantidades Arquivos" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">ID</span>
                            <span>{{ slotProps.data.fila_implantacao.quantidade_arquivos }}</span>
                        </template>
                    </Column>


                    <!-- Incluir a feature futura -->
                    <!-- <Column field="usuario" header="Auditor" :sortable="true"
                        style="text-align: center;" headerStyle="width:12%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Modo de Falha</span>
                            <Tag severity="primary" style="background: #ff4d4f;"
                               :value="slotProps.data.usuario_auditor">
                            </Tag>
                        </template>
                    </Column> -->
                    
                    <Column field="data_fila" header="Data Envio" :sortable="true"
                        headerStyle="width:10%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Data</span>
                            {{ formatarData(slotProps.data.created_at) }}
                        </template>
                    </Column>

                    <Column field="fatura" header="Fatura" :sortable="true" headerStyle="width:10%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Link fatura</span>

                            <Button
                             label="Acessar Fatura"
                            class="p-button-sm p-button-grey"
                            @click="abrirFatura(slotProps.data.nome_arquivo)"
                            />
                        </template>
                    </Column>

                    <Column field="platypus" header="Go to" :sortable="true" headerStyle="width:10%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Link fatura</span>

                            <Button
                            :disabled="slotProps.data.status ? true : false"
                             label="Acessar o platypus"
                            class="p-button-sm p-button-yellow"
                            @click="abrirFatura('https://platypus.amee.com.br/login.php')"/>
                        </template>
                    </Column>

                    
                    <Column header="">
                        <template #body="slotProps">
                            
                            <ConfirmDialog></ConfirmDialog>
                        <Button 
                            :disabled="slotProps.data.status ? true : false"
                            icon="pi pi-pencil" 
                            class="p-button-rounded p-button-info mr-2" 
                            @click="confirmarAuditoria(slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <div v-if="isLoading" class="loading-bar-container">
      <div class="loading-bar"></div>
    </div>
</template>

<style>
.loading-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1); /* Fundo leve para destacar o progresso */
  z-index: 9999;
}

.loading-bar {
  height: 100%;
  background-color: #53C65F; /* Cor de sucesso */
  width: 0; /* Começa vazia */
  animation: loading-animation 2.5s linear forwards; /* Animação predefinida */
}

@keyframes loading-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';
import { useConfirm } from "primevue/useconfirm";

export default {
    mixins: [funcoes],
    data() {
        return {
            confirm: null,
            tipos_erros: [
                { label: "Layout não Encontrado", value: 1 },
                { label: "Campos não Mapeados", value: 2 },
                { label: "Campos não Extraidos ", value: 3 },
                { label: "Implantação Divergente", value: 4 },
            ],
            isLoading: false,
            loadingBarColor: '',
            loading: false,
            filtros: [],
            ModalFiltros: false,
            
            selectImplantacoes: null,
        
            errosCockpit: [],
            errosCockpitFatura: [],
            tiposItensNormalizados: [],
            tiposClassesUnidades: [],
            tiposSubClassesUnidades: [],
            tiposModalidadesUnidades: [],

            fatura: {},
            modalFatura: false,

            itemFaturado: {},
            modalItemFaturado: false,

            medidor: {},
            modalEditMedidor: false,
            medidorDialog: false,

            dados_faturas: {},
            modalEditDados: false,

            dados_fiscais: {},
            modalEditDadosFiscais: false,

            dados_tributos: {},
            modalEditDadosTributos: false,

            deleteModalFatura: false,
            deleteMedidorDialog: false,
            deleteDadosDialog: false,
            deleteDadosFiscaisDialog: false,
            deleteDadosTributosDialog: false,
            deleteItensFaturadosDialog: false,
            deleteProductsDialog: false,

            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            submitted: false,

            tipos_status: [
                { label: "Processo Iniciado", value: 0 },
                { label: "Separação Iniciada", value: 1 },
                { label: "Separação Finalizada", value: 2 },
                { label: "Extração Iniciada", value: 3 },
                { label: "Extração Finalizada", value: 4 },
                { label: "Implantação Iniciada", value: 5 },
                { label: "Implantação Finalizada", value: 6 },
            ],

            tipos_empresas: [],
            desc_tipos_empresas: [],
            tipos_insumo: [],
            tipos_modalidades: [],
            tipos_concess: [],
            tipos_pagamentos: [],
            tipos_classe_tarifaria: [],
            tipos_subclasse: [],
            tipos_tensao: [],
            tipos_ligacao: [],
            status_array: [
                'Upload Iniciado',
                'Separação Iniciada ',
                'Separação Finalizada',
                'Extração Iniciada',
                'Extração Finalizada',
                'Implantação Iniciada',
                'Implantação Finalizada'
            ]
        };
    },

    created() {
        this.initFilters();
    },

    mounted() {
        this.listarErrosCockpitExtracao();
        this.confirm = useConfirm();
    },

    methods: {

        /**
         * Open platypus website
         */
        openPlatypus() {
            window.open('https://platypus.amee.com.br', '_blank');
        },

        
        /**
         * Format data from data base to a pattern dd/mm/yyyy
         * @param dataISO 
         */
        formatarData(dataISO) {
            const data = new Date(dataISO);
            const dia = String(data.getDate()).padStart(2, '0');
            const mes = String(data.getMonth() + 1).padStart(2, '0');
            const ano = data.getFullYear();
            return `${dia}/${mes}/${ano}`;
        },



        /**
         * AuditarFatura - finish the fix needed
         * 
         * Call: API endpoint to update 
         * 
         * @param fatura - archive data
         */
        AuditarFatura(fatura) {

            let dados = {
                id: fatura.id,
                status: '1',
                id_fila: fatura.fila_implantacao.id,
                id_status_erro: fatura.status_erro.id_tipo_status_erro,
                nome_arquivo: fatura.nome_arquivo,
            };

            this.axios
                .post("/cockpit/extracao/editar", dados)
                .then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: 'Fatura auditada com sucesso.',
                        life: 2500,
                    });
                    this.listarErrosCockpitExtracao();
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                });
            });
        },

        /**
         *  Alert to confirm user actions
         */
        confirmarAuditoria(fatura) {
            this.confirm.require({
                message: "Tem certeza que deseja dar baixar nesta fatura?",
                header: "Confirmação",
                icon: "pi pi-exclamation-triangle",
                acceptLabel: "Sim",
                rejectLabel: "Cancelar",
                acceptClass: "p-button-success",
                rejectClass: "p-button-secondary",
                accept: () => {
                    this.AuditarFatura(fatura);
                }
            });
        },

        abrirFatura(link) {
            
            if (!link) {
                this.$toast.add({ severity: 'warn', summary: 'Erro', detail: 'Fatura não encontrada', life: 3000 });
                return;
            }

            window.open(link, '_blank');
    
        },

        

        /**
         * salvarEdicao - save a field directly in datatable
         * 
         * @param event 
         */
        salvarEdicao(event) {
            const { data, field, newValue } = event;

            // Verifica se 'data' e 'field' são válidos antes de atribuir
            if (data && field && Object.prototype.hasOwnProperty.call(data, field)) {
                data[field] = newValue;
            } else {
                console.error("Erro ao salvar edição: dados ou campo inválidos", data, field);
            }
        },
        
        /**
         * deletarLinha - Delete a line in datatable.
         * 
         * @param item - item to delete
         * @param nomeCampo - Invoice array field name
         */
        deletarLinha(item, nomeCampo) {
            this.fatura[nomeCampo] = this.fatura[nomeCampo].filter(i => i.id !== item.id);
        },

        /** 
         * listarClassesUnidades - lista todas as classes cadastradas no sistema
         */
         listarClassesUnidades() {
            this.axios
                .post('/unidades/dados/tipos-classe-tarifaria/listar')
                .then((response) => {
                    this.tiposClassesUnidades = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },

        /** 
         * listarSubClassesUnidades - lista todas as sub classes cadastradas no sistema
         */
         listarSubClassesUnidades() {
            this.axios
                .post('/unidades/dados/tipos-sub-classe/listar')
                .then((response) => {
                    this.tiposSubClassesUnidades = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },

        /** 
         * listarModalidadesUnidades - lista todas as modalidades cadastradas no sistema
         */
         listarModalidadesUnidades() {
            this.axios
                .post('/unidades/dados/tipos-modalidade-tarifaria/listar')
                .then((response) => {
                    this.tiposModalidadesUnidades = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },

        abrirFiltros() {
            this.ModalFiltros = true;
        },

        listarErrosCockpitExtracao() {
            this.axios
                .post("/cockpit/extracao/listar")
                .then((response) => {
                    this.errosCockpit = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },

        listarTiposLigacao() {
            this.axios
                .post('/unidades/dados/tipos-ligacao/listar')
                .then((response) => {
                    this.tipos_ligacao = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },

        listarItensNormalizados() {
            this.axios
                .post('/dicionario/normalizados')
                .then((response) => {
                    this.tiposItensNormalizados = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },


        


        abrirModalEdicaoFatura(id_fatura, erros) {
            this.axios
                .post("/faturas/listar-fatura", {'id_fatura': id_fatura})
                .then((response) => {
                    this.fatura = response.data.dados;
                    this.fatura.link_fatura = this.fatura.link_fatura.replace('.json', '');
                    
                    this.errosCockpitFatura = erros.filter(erro => erro.status === 0);

                    this.modalFatura = true;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },

        salvarFatura() {

            this.loading = true;
            this.axios
                .post("/cockpit/validar-erros", this.fatura)
                .then((response) => {
                    this.fatura = response.data.dados.fatura
                    this.errosCockpitFatura = response.data.dados.erros

                    if (this.errosCockpitFatura.length === 0) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Tudo certo!",
                            detail: "A fatura não apresenta mais erros",
                            life: 2500,
                        });

                        this.isLoading = true;
                        this.loading = false;
                        this.listarErrosCockpit();
                    } else {
                        this.$toast.add({
                            severity: "warn",
                            summary: "Atenção!",
                            detail: "Existem erros na fatura. Por favor, revise-os.",
                            life: 2500,
                        });

                        this.isLoading = true;
                        this.loading = false;
                     
                    }
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },

        abrirModalAdicaoItemFaturado() {            
            this.itemFaturado = {
                "id": null,
                "cci": null,
                "id_classificacao": null,
                "descricao": {
                    "id": null,
                    "descricao": null
                },
                "unidade_medida": null,
                "quantidade": null,
                "tarifa_com_tributos": null,
                "tarifa_sem_tributos": null,
                "valor": null,
                "aliquota_icms": null,
                "valor_icms": null,
                "valor_pis": null,
                "valor_cofins": null,
                "valor_pis_cofins": null,
                "valor_base_calculo_icms": null,
                "valor_base_calculo_pis_cofins": null,

            };

            this.modalItemFaturado = true;
        },

        abrirModalEdicaoItemFaturado(itemFaturado) {
            this.itemFaturado = { ...itemFaturado };
            this.modalItemFaturado = true;
        },

        salvarItemFaturado() {
            const descricaoNormalizada = this.tiposItensNormalizados.find(
                i => i.id === this.itemFaturado.id_classificacao
            );

            this.itemFaturado.descricao_normalizada = descricaoNormalizada;
            this.itemFaturado.descricao = descricaoNormalizada.descricao;

            if(this.itemFaturado.id) {
                const i = this.fatura.dados_itens_faturados.findIndex(i => i.id === this.itemFaturado.id);

                if(i !== -1) {
                    this.fatura.dados_itens_faturados[i] = { ...this.itemFaturado };
                }
            } else {
                const idsExistentes = this.fatura.dados_itens_faturados.map(i => i.id).filter(id => id != null);
                const novoId = idsExistentes.length > 0 ? Math.max(...idsExistentes) + 1 : 1;

                this.itemFaturado.id = novoId;
                this.fatura.dados_itens_faturados.push(this.itemFaturado);
            }

            this.itemFaturado = {};
            this.modalItemFaturado = false;
        },

        deleteItemFaturado(idItemFaturado) {
            this.fatura.dados_itens_faturados = this.fatura.dados_itens_faturados.filter(
                (i) => i.id !== idItemFaturado
            );
        },

        editDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor = { ...medidor };
        },

        editDadosFiscais(dados_fiscais) {
            this.modalEditDadosFiscais = true;
            this.dados_fiscais = { ...dados_fiscais };
        },

        editDadosTributos(dados_tributos) {
            this.modalEditDadosTributos = true;
            this.dados_tributos = { ...dados_tributos };
        },



        editDadosFatura(dados_faturas) {
            this.modalEditDados = true;
            this.dados_faturas = { ...dados_faturas };
        },

        hideDialog() {
            this.ModalFatura = false;
            this.submitted = false;
        },

        addDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor.id_fatura = medidor.id;
        },

        


        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};




</script>


<style scoped>


    .p-button.p-button-sm {
        font-size: 0.9rem !important;
        padding: 0.65625rem 1.09375rem;
        height: 100% !important;
    }
</style>